<template>
  <div class="kbars">
    <div class="kbars--legend">
      <TeamCell :image="leftTeam.icon_url" :name="leftTeam.name" variant="row" />
      <TeamCell :image="rightTeam.icon_url" :name="rightTeam.name" variant="row" />
    </div>
    <div class="kbar" :style="{ '--left': leftKills, '--right': rightKills }">
      <span class="kbar--legend">{{ leftKills }}</span>
      <span class="kbar--bar">Kills</span>
      <span class="kbar--legend">{{ rightKills }}</span>
    </div>
    <div class="kbar" :style="{ '--left': leftDeaths, '--right': rightDeaths }">
      <span class="kbar--legend">{{ leftDeaths }}</span>
      <span class="kbar--bar">Deaths</span>
      <span class="kbar--legend">{{ rightDeaths }}</span>
    </div>
    <div class="kbar" :style="{ '--left': leftAssists, '--right': rightAssists }">
      <span class="kbar--legend">{{ leftAssists }}</span>
      <span class="kbar--bar">Assists</span>
      <span class="kbar--legend">{{ rightAssists }}</span>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import TeamCell from '../../Table/cells/TeamCell.vue'

export default {
  name: 'MatchStatKillBars',
  components: { TeamCell },
  props: {
    teams: px.arrayOf(
      px.shape({
        icon_url: px.string,
        name: px.string,
        players: px.arrayOf(
          px.shape({
            assists: px.number,
            deaths: px.number,
            kills: px.number,
          }).loose
        ),
      }).loose
    ).isRequired,
  },
  computed: {
    leftTeam() {
      return this.teams[0]
    },
    rightTeam() {
      return this.teams[1]
    },
    leftKills() {
      return this.sum(this.leftTeam, p => p.kills)
    },
    rightKills() {
      return this.sum(this.rightTeam, p => p.kills)
    },
    leftDeaths() {
      return this.sum(this.leftTeam, p => p.deaths)
    },
    rightDeaths() {
      return this.sum(this.rightTeam, p => p.deaths)
    },
    leftAssists() {
      return this.sum(this.leftTeam, p => p.assists)
    },
    rightAssists() {
      return this.sum(this.rightTeam, p => p.assists)
    },
  },
  methods: {
    sum({ players }, map) {
      return players.reduce((sum, p) => sum + map(p), 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.kbars {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  &--legend {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}

.kbar {
  display: flex;
  flex-flow: row nowrap;
  padding: 0.125em 0.5em;

  --sum: calc(var(--left) + var(--right));
  --mid: calc(100% * var(--left) / var(--sum));

  &--bar {
    flex: 1 1 100%;
    text-align: center;
    color: $body-bg;
    background: linear-gradient(90deg, $atk 0%, $atk var(--mid), $def var(--mid), $def 100%);
  }

  &--legend {
    display: block;
    padding: 0 0.25em;
  }
}
</style>
